import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Api from '../../../../api/global.service.js';
import 'react-datepicker/dist/react-datepicker.css';
import formatNumber from '../../../../utils/formatNumber.js';
import './../entregas-x-lote.css';

import { PieChart, Pie, Cell, ResponsiveContainer, Label } from 'recharts';

import BlockUi from 'react-block-ui';
import env from '../../../../environment.js';
import { useCoopConfig } from '../../../../components/content/coop-config.jsx';
import { Colors } from '../../../../constants';

const ModalInfoLote = forwardRef((props, ref) => {
  const COLORS_DON = ['#FFEED9', '#BC8268'];
  const coopConfig = useCoopConfig();
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [idLote, setIdLote] = useState(0);
  const [dataGrafica, setDataGrafica] = useState([]);
  const [data, setData] = useState([]);

  const [errors, setErrors] = useState(null);

  //To call outside
  useImperativeHandle(ref, () => ({
    show: showHandler,
    setIdLote: id_lote => setIdLote(id_lote),
  }));

  useEffect(() => {
    setData([]);
    setDataGrafica([]);
    if (idLote > 0) {
      getData();
    }
  }, [idLote]);

  const showHandler = () => {
    setShow(true);
    setErrors(null);
  };

  const closeHandler = () => {
    setIdLote(0);
    props.onClose && props.onClose();
    setShow(false);
  };

  const handleEdit = (data) => {
    props.setInfoLote(data);
    closeHandler();
  };

  const handleDelete = (data) => {
    props.eliminarLote(data);
    closeHandler();
  };

  const getData = () => {
    setBlocking(true);
    Api.Acopio.get('lotes_info/' + idLote)
      .then(response => {
        setBlocking(false);
        setShow(true);
        if (typeof response.data[0] !== 'undefined') {
          setData(response.data[0]);
          setDataGrafica([
            { name: 'Totales', value: 100 },
            {
              name: 'Por entregar',
              value: (Number(response.data[0].lote_kgs_entregados) / Number(response.data[0].lote_kgs_totales)) * 100,
            },
          ]);
        } else {
          let err = response.data.data;
          setErrors(err);
        }
      })
      .catch(e => {
        setShow(false);
        setBlocking(false);
      });
  };

  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <div className="modal-dialog" style={{ top: 0, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 50px)' }}>
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header" style={{ flex: 'inherit', position: 'relative' }}>
            <button onClick={() => closeHandler()} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <strong>{'>>'}</strong>
            </button>
            <div className="modal-title" style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%', width: 'fit-content' }}>
              {data.nombre && (
                <h2>
                  {data.nombre}
                </h2>
              )}
            </div>
          </div>
          <div className="modal-body">
            <BlockUi tag="div" message="" blocking={blocking} renderChildren={true}>
              {data.lote_kgs_totales && (
                <ResponsiveContainer width="100%" height={'100%'}>
                  <div className="row align-items-center">
                    {data.lote_kgs_totales > 0 && (
                      <>
                        <div className="col-6">
                          <span className="lotes-kgs-total-label">{`${coopConfig.acopioUnits()?.abreviacion_plural} totales de lote: `}</span>{' '}
                        </div>
                        <div className="col-6">
                          <span className="lotes-kgs-total-valor">{formatNumber(data?.lote_kgs_totales)}</span>
                        </div>
                      </>
                    )}
                    <div className="col-6">
                      {data.folio && (<p className="lotes-kgs-total-label m-b-0">folio: </p>)}
                    </div>
                    <div className="col-6"><span className="lotes-kgs-total-valor">{data.folio}</span></div>
                    <div className="col-6">
                      {data.fecha_entrega_venta && (<p className="lotes-kgs-total-label">fecha de venta/entrega: </p>)}
                    </div>
                    <div className="col-6">
                      <span className="lotes-kgs-total-valor">{data.fecha_entrega_venta}</span>
                    </div>
                  </div>
                  <>
                    {data.lote_kgs_totales > 0 && (
                      <div className="row justify-content-center">
                          <PieChart width={250} height={250} className={"offset-1"}>
                            <Pie
                              data={dataGrafica}
                              cx={100}
                              cy={125}
                              innerRadius={60}
                              outerRadius={90}
                              fill="#8884D8"
                              paddingAngle={1}
                              dataKey="value">
                              {dataGrafica.map((entry, index) => (
                                <React.Fragment key={`cell-${index}`}>
                                  <Cell fill={COLORS_DON[index % COLORS_DON.length]} />
                                  <Label
                                    style={{ fontSize: 18 }}
                                    value={parseFloat(dataGrafica[1]?.value).toFixed(2) + '%'}
                                    position="center"
                                  />
                                </React.Fragment>
                              ))}
                            </Pie>
                          </PieChart>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-1 offset-3">
                        <img style={{ paddingTop: 20 }} src={env.images.card.circulo_BC8268} />
                      </div>

                      <div className="col-6">
                        <p className="lotes-kgs-entregados-valor">{formatNumber(data?.lote_kgs_entregados)}</p>
                        <p className="lotes-kgs-entregados-label">{coopConfig.acopioUnits()?.abreviacion_plural} entregados</p>
                      </div>
                    </div>

                    {data.lote_kgs_totales > 0 && (
                      <div className="row">
                        <div className="col-1 offset-3">
                          <img style={{ paddingTop: 20 }} src={env.images.card.circulo_FFEED9} />
                        </div>
                        <div className="col-6">
                          <p className="lotes-kgs-entregados-valor">{formatNumber(data?.lote_kgs_por_entregar)}</p>
                          <p className="lotes-kgs-entregados-label">{coopConfig.acopioUnits()?.abreviacion_plural} por entregar</p>
                        </div>
                      </div>
                    )}
                  <div className="row m-t-40">
                    <div className="col-2"></div>

                  <div className="col-4">
                    <button
                      onClick={() => handleEdit(data)}
                      type="button"
                      className="btn btn-lg button-hover"
                      style={{
                        color: Colors.primary_500,
                        borderColor: Colors.primary_500,
                        borderRadius: '20px 20px 20px 20px',
                        flexGrow: 1,
                        flexBasis: 'auto',
                      }}>
                      <span style={{ color: Colors.primary_500 }}>
                      <i className="fa fa-edit"></i> {'Editar'}
                      </span>
                    </button>
                  </div>
                  <div className="col-4">
                    <button
                      onClick={() => handleDelete()}
                      type="button"
                      className="btn btn-lg button-hover"
                      style={{
                        borderColor: 'red',
                        borderRadius: '20px 20px 20px 20px',
                        flexGrow: 1,
                        flexBasis: 'auto',
                        opacity: idLote ? 1 : 0.25,
                      }}
                      disabled={!idLote}>
                      <span style={{ color: 'red' }}>
                        <i className="fa fa-trash pr-2"></i> {' Eliminar'}
                      </span>
                    </button>
                  </div>
                  </div>

                    {/* <hr />
								<div className='row'>
									<div className='col-6 pl-5'>
										<p className='lotes-kgs-entregados-valor'>{formatNumber(data?.lote_kgs_hoy)}</p>
										<p className='lotes-kgs-entregados-label'>kgs entregados hoy</p>
									</div>
									<div className='col-6'>
										<p className='lotes-kgs-entregados-valor'>{formatNumber(data?.lote_kgs_semana)}</p>
										<p className='lotes-kgs-entregados-label'>kgs entregados esta semana</p>
									</div>
								</div> */}
                  </>
                </ResponsiveContainer>
              )}
            </BlockUi>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalInfoLote;
