import React, { useState, useEffect, createRef } from 'react';
import { Link } from 'react-router-dom';
import CardContainer from '../../../components/card/card-container.jsx';
import Breadcrumb from '../../../components/breadcrumb/breadcrumb.jsx';
import Api from '../../../api/global.service';
import env from '../../../environment.js';
import { subscribe, unsubscribe } from '../../../components/events';
import EntregasXProductorFirma from './entregas-x-productor-firma.js';
import EntregasXProductorInfo from './entregas-x-productor-info.js';
import EntregasXProductorBuscador from './entregas-x-productor-buscador.js';
import './entregas-x-productor.css';
import ExampleCustomInput from '../../../components/modal/input.jsx';
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { es } from 'date-fns/locale';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import FormInputMaterialTest from '../../../nuup/componentes/Forms/FormInputMaterialTest.jsx';

import FormSelectMaterial from '../../../nuup/componentes/Forms/FormSelectMaterial.jsx';

import { useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import formatMoneyNumber, { formatNumber } from '../../../utils/formatNumber.js';
import {
  useCoopConfig,
  precioPorKgPrecargado,
  precioPorKgPorProductor,
  getIdCatCreditoPrecosecha,
} from '../../../components/content/coop-config.jsx';
import transformWord from '../../../utils/transformWord.js';
import moment from 'moment';
import EntregasXProductorBultos from './entregas-x-productor-bultos.js';
import FormSelectApi from '../../../nuup/componentes/Forms/FormSelectApi.jsx';
import zIndex from '@mui/material/styles/zIndex.js';

yup.addMethod(yup.number, 'campoObligatorio', function(errorMessage, valuesPesadas) {
  return this.test(`error-obligatorio`, errorMessage, function(value, context) {
    const { path, createError } = this;
    let isValid = true;
    /*
      path : pesadas[0].bultos[2].no_bultos
    */
    let pathArr = path.split('.bultos');
    let numeroPesada = Number(pathArr[0].substring(pathArr[0].indexOf('[') + 1, pathArr[0].lastIndexOf(']')));
    let id_cat_unidad_entrega = Number(pathArr[1].substring(pathArr[1].indexOf('[') + 1, pathArr[1].lastIndexOf(']')));

    if (Number(id_cat_unidad_entrega) !== 7) {
      if (typeof valuesPesadas[numeroPesada] !== 'undefined') {
        let id_cat_unidades_entregas = valuesPesadas[numeroPesada].id_cat_unidades_entregas;
        if (id_cat_unidades_entregas.includes(Number(id_cat_unidad_entrega))) {
          if (Number.isInteger(value) && Number(value) > 0) {
            isValid = true;
          } else {
            isValid = false;
          }
        }
      }
    }
    return isValid || createError({ path, message: errorMessage });
  });
});

let curr = moment(new Date()).toDate();
const ID_MIEL = 3;

const EntregasXProductor = () => {
  const MSJ_REQUERIDO = 'Información obligatoria';
  const coopConfig = useCoopConfig();

  const { id_cuenta, uuid_cuenta, uuid_acopio } = useParams();
  const [dataForm, setDataForm] = useState({});
  const [idCatUnidadesEntregas, setIdCatUnidadesEntregas] = useState([]);
  const [idCatCertificacion, setIdCatCertificacion] = useState([]);
  const [pesoNeto, setPesoNeto] = useState();
  const [importeBruto, setImporteBruto] = useState(null);
  const [montoPagar, setMontoPagar] = useState(0);
  const [showForm, setShowForm] = useState(true);
  const [showFirma, setShowFirma] = useState(false);
  const [metodosPago, setMetodosPago] = useState([]);
  const [coloresMiel, setColoresMiel] = useState([]);
  const [tiposCafe, setTiposCafe] = useState([]);
  const [procesosCafe, setProcesosCafe] = useState([]);
  const [unidadesEntregas, setUnidadesEntregas] = useState([]);
  const [pendientePagoCredito, setPendientePagoCredito] = useState(0);
  const [lotes, setLotes] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [estimacion, setEstimacion] = useState();
  const [entrega, setEntrega] = useState();
  const [cuenta, setCuenta] = useState();
  const [etiquetaAnticipos, setEtiquetaAnticipos] = useState('-');
  const [idMetodoPago, setIdMetodoPago] = useState('');
  const [idColorMiel, setIdColorMiel] = useState('');
  const [idTipoCafe, setIdTipoCafe] = useState('');
  const [idProcesoCafe, setIdProcesoCafe] = useState(1);
  const [devuelvenCostaleras, setDevuelvenCostaleras] = useState(null);
  const [valuesPesadas, setValuesPesadas] = useState([]);
  const muestreoPermissions = [
    { key: 'rendimiento', permissions: [1, 2, 4] },
    { key: 'humedad', permissions: [1, 2, 4] },
    { key: 'mancha', permissions: [1, 2, 4] },
    { key: 'aroma', permissions: [1] },
    { key: 'color_de_cafe', permissions: [2, 4] },
    { key: 'tipo_maduracion', permissions: [3] },
  ];

  useEffect(() => {
    getEtiquetaAnticipo();
  }, [coopConfig.config]);

  const getEtiquetaAnticipo = async () => {
    let etiqueta = await coopConfig.etiquetaAnticipos();
    setEtiquetaAnticipos(etiqueta);
  };

  const customStyles = {
    menu: provided => ({ 
      ...provided, 
      zIndex: 9999,
      position: 'absolute'
    }),
    control: base => ({
      ...base,
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        color: '#666',
        fontSize: 14,
      };
    },
  };

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className="fa fa-search" aria-hidden="true" style={{ position: 'initial' }}></i>
        </components.DropdownIndicator>
      )
    );
  };

  const handleChange = selected => {
    let value = selected?.value ?? null
    setValue('id_recoleccion_acopio', value);
  };

  useEffect(() => {
    getMetodosPago();
    getColoresMiel();
    getUnidadesEntregas();
    getPrecioKgPrecargado();
    getTiposCafe();
    getProcesosCafe();
    handlerSelectTipoCafe(1);
  }, []);

  const getPrecioKgPrecargado = () => {
    if (precioPorKgPrecargado()) {
      // Obneter el precio por cooperativa
      Api.getAll('admin/acopios/precio_por_kg_precargado').then(response => {
        if (typeof response.data[0] !== 'undefined') {
          if (typeof response.data[0].precio !== 'undefined') {
            setValue('precio_por_kg', response.data[0].precio);
          }
        }
      });

      if (precioPorKgPorProductor()) {
        // Obneter el precio por productor
        Api.get('admin/acopios/precio_por_kg_por_productor', id_cuenta).then(response => {
          if (typeof response.data[0] !== 'undefined') {
            if (typeof response.data[0].precio !== 'undefined') {
              setValue('precio_por_kg', response.data[0].precio);
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    subscribe('Entrega::onReturn', e => handleReturn(e));
    subscribe('Entrega::Estimacion', e => handleEstimacion(e));
    subscribe('Entrega::Entregas', e => handleEntregas(e));
    subscribe('Entrega::Pagos', e => handlePagos(e));
    subscribe('Entrega::Creditos', e => handleCreditos(e));
    subscribe('Entrega::Cuenta', e => handleCuenta(e));
    return () => {
      unsubscribe('Entrega::onReturn');
      unsubscribe('Entrega::Estimacion');
      unsubscribe('Entrega::Entregas');
      unsubscribe('Entrega::Pagos');
      unsubscribe('Entrega::Creditos');
      unsubscribe('Entrega::Cuenta');
    };
  }, []);

  const handleCuenta = event => {
    setCuenta(event.detail);
  };

  const handleEstimacion = event => {
    setEstimacion(event.detail);
  };

  const handleEntregas = event => {
    setEntrega(event.detail);
  };

  const handlePagos = () => {
    //TODO: remove?
  };

  const handleCreditos = event => {
    setValue('creditos', event.detail.raw);
    setPendientePagoCredito(event.detail.total_saldo);
  };

  const handleReturn = () => {
    setShowForm(true);
    setShowFirma(false);
  };

  const [formData] = useState({
    id_acopio: 0,
    id_cuenta: id_cuenta,
    id_ciclo: localStorage.getItem(`${env.appName}-ciclo_id`),
    uuid: '',
    fecha_ejecucion: curr,
    fecha_cosecha: null,
    pesadas: [
      {
        id_acopio_pesada: 0,
        id_cat_unidades_entregas: [],
        peso_bruto: '',
        peso_tara: '',
        peso_descuento: '',
        bultos: [],
      },
    ],
    id_acopio_pesada: 0,
    id_acopio_muestreo: 0,
    tipo_maduracion: '',
    rendimiento: '',
    humedad: '',
    mancha: '',
    id_color: '',
    color_de_cafe: '',
    aroma: '',
    muestreo_observaciones: '',
    id_cat_acopio_tipos_cafe: '',
    id_cat_acopio_procesos: '',
    lote: '',
    observaciones: '',
    id_cat_certificacion: '',
    id_acopio_pago: 0,
    precio_por_kg: '',
    recuperacion_credito: '',
    id_metodo_pago: '',
    monto_pagado: '',
    id_recoleccion_acopio: '',
    creditos: [],
    firma_image: '',
    id_cat_costaleras_estatus: null,
  });

  let patternNumero2Decimales = /^\d+(\.\d{0,2})?$/;
  const validacionNumero2Decimales = yup
    .number()
    .transform(value => (isNaN(value) ? undefined : value))
    .test('is-number', 'Se espera un número', (value) => {
      return typeof value === 'number';
    })
    .min(0, 'Se espera que sea mayor o igual a 0')
    .test('is-decimal', 'Solo se permiten dos decimales', val => {
        return patternNumero2Decimales.test(val);
    });

  const validacionPorcentajes = () => {
    return yup
      .number()
      .transform((value, originalValue) => {
        if (originalValue === '' || originalValue === null || originalValue === undefined) {
          return null;
        }
        const number = Number(originalValue);
        return isNaN(number) ? undefined : number;
      })
      .test('is-number', 'El valor debe ser un número', value => {
        if (value === null) return true;
        return typeof value === 'number' && !isNaN(value);
      })
      .test('is-positive', 'Se espera un valor entero entre 0 y 100', value => {
        if (value === null || value === undefined) return true;
        return value >= 0;
      })
      .test('is-decimal', 'Solo se permiten dos decimales', value => {
        if (value === null || value === undefined) return true;
        return patternNumero2Decimales.test(value);
      })
      .test('is-positive', 'Se espera un valor entero entre 0 y 100', value => {
        if (value === null || value === undefined) return true;
        return value <= 100;
      })
      .nullable();
  };

  const bultosSchema = {
    no_bultos: yup
      .number()
      .integer('Debe introducir un valor entero')
      .transform(value => (isNaN(value) ? undefined : value))
      .campoObligatorio(MSJ_REQUERIDO, valuesPesadas),
  };

  const pesadaSchema = {
    id_acopio_pesadas: yup
      .number()
      .min(0)
      .nullable(),
    id_cat_unidades_entregas: yup
      .array()
      .min(1, 'Seleccione al menos una opción')
      .required('Seleccione al menos una opción'),
    peso_bruto: validacionNumero2Decimales.min(0.01, 'se espera que sea mayor a 0').required(MSJ_REQUERIDO),
    peso_tara: validacionNumero2Decimales.min(0.01, 'se espera que sea mayor a 0').required(MSJ_REQUERIDO),
    peso_descuento: yup
      .number()
      .transform((value, originalValue) => {
        if (originalValue === '' || originalValue === null || originalValue === undefined) {
          return null;
        }
        const number = Number(originalValue);
        return isNaN(number) ? undefined : number;
      })
      .test('is-number', 'El valor debe ser un número', value => {
        if (value === null) return true;
        return typeof value === 'number' && !isNaN(value);
      })
      .test('is-positive', 'El monto debe ser igual o mayor a 0', value => {
        if (value === null || value === undefined) return true;
        return value >= 0;
      })
      .test('is-decimal', 'Solo se permiten dos decimales', value => {
        if (value === null || value === undefined) return true;
        return patternNumero2Decimales.test(value);
      })
      .lessThan(yup.ref('peso_bruto'), 'El peso descuento debe ser menos al peso bruto')
      .nullable(),
    bultos: yup.array().of(yup.object().shape(bultosSchema)),
  };

  const schema = yup.object().shape({
    id_acopio: yup
      .number()
      .min(0)
      .nullable(),
    id_acopio_pesada: yup
      .number()
      .min(0)
      .required(),
    id_acopio_muestreo: yup
      .number()
      .min(0)
      .required(),
    id_acopio_pago: yup
      .number()
      .min(0)
      .required(),
    id_cuenta: yup.number().min(1),
    uuid: yup.string(),
    fecha_ejecucion: yup
      .date()
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .required(MSJ_REQUERIDO),
    fecha_cosecha: yup.date().nullable(),
    // .transform((curr, orig) => (orig === '' ? null : curr)),
    // .required(MSJ_REQUERIDO),
    pesadas: yup.array().of(yup.object().shape(pesadaSchema)),
    tipo_maduracion: yup.string().nullable(),
    rendimiento: validacionPorcentajes(),
    humedad: validacionPorcentajes(),
    mancha: validacionPorcentajes(),
    id_color: yup.string().nullable(),
    color_de_cafe: yup.string().nullable(),
    aroma: yup.string().nullable(),
    observaciones: yup.string().nullable(),
    id_cat_certificacion: yup
      .string()
      .test('len', 'Información requerida', val => val.length > 0 || !coopConfig.mostrarTipo())
      .nullable(),
    precio_por_kg: yup
      .number()
      .transform((value, originalValue) => {
        if (originalValue === '' || originalValue === null || originalValue === undefined) {
          return 0;
        }
        const number = Number(originalValue);
        return isNaN(number) ? undefined : number;
      })
      .test('is-number', 'El valor debe ser un número', value => {
        if (value === null) return true;
        return typeof value === 'number' && !isNaN(value);
      })
      .test('is-positive', 'El precio debe ser mayor a 0', value => {
        if (value === null || value === undefined) return true;
        return value >= 0;
      })
      .test('is-decimal', 'Solo se permiten dos decimales', value => {
        if (value === null || value === undefined) return true;
        return patternNumero2Decimales.test(value);
      })
      .nullable(),
    recuperacion_credito: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .when([], {
        is: () => formatNumber(pendientePagoCredito) > 0,
        then: yup
          .number()
          .min(0, 'Se espera un valor mayor o igual a 0')
          .max(formatNumber(pendientePagoCredito), 'Se espera un valor menor o igual a ' + formatMoneyNumber(pendientePagoCredito))
          .transform(value => (isNaN(value) ? undefined : value))
          .nullable(),
        otherwise: yup
          .number()
          .min(0, 'Se espera un valor mayor o igual a 0')
          .max(formatNumber(pendientePagoCredito), 'Se espera un valor menor o igual a ' + formatMoneyNumber(pendientePagoCredito))
          .transform(value => (isNaN(value) ? undefined : value))
          .nullable(),
      }),
    monto_pagado: yup
      .number()
      .transform((value, originalValue) => {
        if (originalValue === '' || originalValue === null || originalValue === undefined) {
          return null;
        }
        const number = Number(originalValue);
        return isNaN(number) ? undefined : number;
      })
      .test('is-number', 'El valor debe ser un número', value => {
        if (value === null) return true;
        return typeof value === 'number' && !isNaN(value);
      })
      .test('is-positive', 'El monto debe ser mayor a 0', value => {
        if (value === null || value === undefined) return true;
        return value >= 0;
      })
      .test('is-decimal', 'Solo se permiten dos decimales', value => {
        if (value === null || value === undefined) return true;
        return patternNumero2Decimales.test(value);
      })
      .max(formatNumber(montoPagar), 'El monto no debe ser mayor a $ ' + formatMoneyNumber(montoPagar))
      .nullable(),
    id_metodo_pago: yup.number().when('monto_pagado', {
      is: val => val > 0,
      then: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .required(MSJ_REQUERIDO),
      otherwise: yup
        .number()
        .transform(value => (isNaN(value) ? undefined : value))
        .nullable(),
    }),

    id_recoleccion_acopio: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .nullable(),
    creditos: yup
      .array()
      .min(0)
      .nullable(),
    firma_image: yup.string().nullable(),
    id_cat_costaleras_estatus: yup
      .number()
      .transform(value => (isNaN(value) ? undefined : value))
      .nullable(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    getValues,
    clearErrors
  } = useForm({
    resolver: async (data, context, options) => {
      return yupResolver(schema)(data, context, options);
    },
    mode: 'onBlur',
    defaultValues: formData,
  });

  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: 'pesadas',
  });

  const precioKgValue = watch('precio_por_kg');

  const isValidPositiveNumber = (value) => {
    const number = Number(value);
    return !isNaN(number) && number > 0 && patternNumero2Decimales.test(number);
  };

  const handlerSelectChange = (value, index) => {
    setIdCatUnidadesEntregas(value);
    setValue(`pesadas[${index}].id_cat_unidades_entregas`, value);
  };

  const handlerSelectChangeTipos = selectedOption => {
    setIdCatCertificacion(selectedOption);
    let _selectedOption = selectedOption
    if (_selectedOption.length > 1) {
      let selectedOptionFilter = selectedOption.filter(option => [15, 16].includes(Number(option)));
      if (selectedOptionFilter.length === 2) {
        _selectedOption = selectedOption.filter(option => option !== 16);
      }
    }
    setIdCatCertificacion(_selectedOption);
    setValue('id_cat_certificacion', _selectedOption.join(','));
    clearErrors("id_cat_certificacion")
  };

  const handlerSelectMetodoPago = selectedOption => {
    setIdMetodoPago(selectedOption);
    setValue('id_metodo_pago', selectedOption);
  };

  const handlerSelectColorMiel = selectedOption => {
    setIdColorMiel(selectedOption);
    setValue('id_color', selectedOption);
  };

  const handlerSelectTipoCafe = selectedOption => {
    setIdTipoCafe(selectedOption);
    setValue('id_cat_acopio_tipos_cafe', selectedOption);
  };

  const handlerSelectProcesoCafe = selectedOption => {
    setIdProcesoCafe(selectedOption);
    setValue('id_cat_acopio_procesos', selectedOption);
  };

  const onSubmit = values => {
    if (!moment(values.fecha_cosecha).isValid()) values.fecha_cosecha = '';
    setDataForm(values);
    setShowFirma(true);
    setShowForm(false);
  };

  useEffect(() => {
    watch(values => {
      let pesadas = values.pesadas;
      setValuesPesadas(pesadas);
      let _pesoNeto = 0;
      let _importeBruto = 0;

      try {
        for (let index = 0; index < pesadas.length; index++) {
          if (pesadas[index].peso_bruto >= 0 && pesadas[index].peso_tara >= 0) {
            _pesoNeto += pesadas[index].peso_bruto - pesadas[index].peso_tara - (pesadas[index].peso_descuento || 0);
          }
        }

        if (_pesoNeto > 0) {
          setPesoNeto(_pesoNeto);
        }

        if (values.precio_por_kg > 0) {
          _importeBruto = _pesoNeto * values.precio_por_kg;
          if (_importeBruto > 0) {
            setImporteBruto(_importeBruto);
          }
        }

        if (values.recuperacion_credito >= 0) {
          if (_importeBruto > 0) {
            setMontoPagar(_importeBruto - values.recuperacion_credito);
          }
        }
      } catch (error) {
        //console.error(error);
      }
    });
  }, [watch]);

  const getUnidadesEntregas = () => {
    Api.getAll('acopios/catalogos/unidades_entrega').then(response => {
      let _items = response.data.map(function(item) {
        return { value: item['id'], label: item['nombre'] };
      });
      setUnidadesEntregas(_items);
    });
  };

  const getMetodosPago = () => {
    Api.getAll('acopios/catalogos/metodos_pago').then(response => {
      let _items = response.data.map(function(item) {
        return { value: item['id'], label: item['nombre'] };
      });
      setMetodosPago(_items);
      if (_items.length === 1) {
        setIdMetodoPago(_items[0].value);
        setValue('id_metodo_pago', _items[0].value);
      }
    });
  };

  const getColoresMiel = () => {
    Api.getAll('acopios/catalogos/colores_miel').then(response => {
      let _items = response.data.map(function(item) {
        return { value: item['id'], label: item['nombre'], color: item['color'] };
      });
      setColoresMiel(_items);
    });
  };

  const getTiposCafe = () => {
    Api.getAll('acopios/catalogos/tipos_cafe').then(response => {
      const items = response.data.map(item => {
        return { value: item['id'], label: item['nombre'] };
      });
      setTiposCafe(items);
    });
  };

  const getProcesosCafe = () => {
    Api.getAll('acopios/catalogos/procesos_cafe').then(response => {
      const items = response.data.map(item => {
        return { value: item['id'], label: item['nombre'] };
      });
      setProcesosCafe(items);
    });
  };

  const checkTipoCafe = key => {
    const permissions = muestreoPermissions.find(m => m.key == key)?.permissions;
    return permissions ? permissions.includes(idTipoCafe) : false;
  };

  useEffect(() => {
    if (uuid_acopio !== undefined && unidadesEntregas.length > 0) {
      getDataEntrega();
    }
  }, [uuid_acopio, unidadesEntregas, tipos]);

  const setdatosGenerales = data => {
    setValue('id_acopio', data.id);
    setValue('uuid', data.uuid);
    setValue('fecha_ejecucion', moment(data.fecha_ejecucion).toDate());
    moment(data.fecha_cosecha).isValid() && setValue('fecha_cosecha', moment(data.fecha_cosecha).toDate());
    setDevuelvenCostaleras(data.id_cat_costaleras_estatus);
    setValue('id_cat_costaleras_estatus', data.id_cat_costaleras_estatus);
    setValue('id_recoleccion_acopio', data.id_recoleccion_acopio);
    setValue('id_cat_acopio_tipos_cafe', data.id_cat_acopio_tipos_cafe);
    setValue('id_cat_acopio_procesos', data.id_cat_acopio_procesos);
    setValue('observaciones', data.observaciones);
    setIdTipoCafe(+data.id_cat_acopio_tipos_cafe);
  };

  const getUnidadesEntrega = (id_acopio_pesadas, acopio_pesada_cat_unidades) => {
    let unidades = [];
    acopio_pesada_cat_unidades
      .filter(apcu => Number(apcu.id_acopio_pesadas) === Number(id_acopio_pesadas))
      .reduce(function(prev, curr) {
        return unidades.push(Number(curr.id_cat_unidades_entregas));
      }, null);
    return unidades;
  };

  const setdatosBultos = (id_acopio_pesadas, acopio_pesada_cat_unidades) => {
    let bultos_data = acopio_pesada_cat_unidades.filter(unidad => Number(unidad.id_acopio_pesadas) === Number(id_acopio_pesadas));
    return bultos_data;
  };

  const setdatosPesada = (acopio_pesadas, acopio_pesada_cat_unidades) => {
    const pesadasData = acopio_pesadas.map((pesada) => {
      const id_cat_unidades_entregas = getUnidadesEntrega(pesada.id, acopio_pesada_cat_unidades);
      
      return {
        id_acopio_pesadas: pesada.id,
        id_cat_unidades_entregas: id_cat_unidades_entregas || [],
        peso_bruto: pesada.peso_bruto / 100,
        peso_tara: pesada.peso_tara / 100,
        peso_descuento: pesada.peso_descuento > 0 ? pesada.peso_descuento / 100 : null,
        bultosData: setdatosBultos(pesada.id, acopio_pesada_cat_unidades),
      };
    });

    pesadasData.forEach((pesada, index) => {
        setValue(`pesadas[${index}]`, pesada);
        remove(index);
        insert(index, pesada);
    });
  };

  const setdatosTipos = acopio_tipos => {
    let tiposArr = [];
    for (let u = 0; u < acopio_tipos.length; u++) {
      tiposArr.push(Number(acopio_tipos[u].id_cat_certificacion));
    }

    setIdCatCertificacion(tiposArr);
    setValue('id_cat_certificacion', tiposArr.join(','));
  };

  const setdatosMuestreo = acopio_muestreos => {
    if (typeof acopio_muestreos[0] !== 'undefined') {
      setValue('id_acopio_muestreo', acopio_muestreos[0].id ?? '');
      setValue('tipo_maduracion', acopio_muestreos[0].tipo_maduracion ?? '');
      setValue('rendimiento', acopio_muestreos[0].rendimiento ?? '');
      setValue('humedad', acopio_muestreos[0].humedad ?? '');
      setValue('mancha', acopio_muestreos[0].mancha ?? '');
      setValue('muestreo_observaciones', acopio_muestreos[0].observaciones ?? '');
      setValue('id_color', acopio_muestreos[0].id_color ?? '');
      setValue('color_de_cafe', acopio_muestreos[0].color_de_cafe ?? '');
      setValue('aroma', acopio_muestreos[0].aroma ?? '');
      setIdColorMiel(acopio_muestreos[0].id_color);
    }
  };

  const setdatosPago = (acopio_pagos, precio_por_kg) => {
    setValue('precio_por_kg', precio_por_kg / 100);
    if (typeof acopio_pagos[0] !== 'undefined') {
      setValue('id_acopio_pago', acopio_pagos[0].id);
      setValue('folio', acopio_pagos[0].folio);
      setValue('id_centro_acopio', acopio_pagos[0].id_centro_acopio);
      let id_metodo_pago = metodosPago.filter(metodo => [Number(acopio_pagos[0].id_metodo_pago)].includes(metodo.value));
      if (id_metodo_pago.length === 1) {
        setValue('id_metodo_pago', id_metodo_pago[0].value);
        setIdMetodoPago(id_metodo_pago[0].value);
      }
      let monto_pagado = 0;
      acopio_pagos.forEach(ap => {
        monto_pagado += ap.total > 0 ? ap.total / 100 : Number(ap.total);
      });
      if (monto_pagado > 0) {
        setValue('monto_pagado', monto_pagado);
      }

      setValue('firma_image', acopio_pagos[0].firma_image);
    }
  };

  const getDataEntrega = () => {
    Api.get('admin/acopios/entregas', uuid_acopio).then(response => {
      if (response.status === 200) {
        let dataEntrega = response.data;
        if (typeof dataEntrega.id !== 'undefined') {
          let _pesoNeto = Number(dataEntrega.peso_bruto) - Number(dataEntrega.peso_tara) - Number(dataEntrega.peso_descuento)
          setdatosGenerales(dataEntrega);
          setdatosPesada(dataEntrega.acopio_pesadas, dataEntrega.acopio_pesada_cat_unidades);
          setdatosMuestreo(dataEntrega.acopio_muestreos);
          setdatosPago(dataEntrega.acopio_pagos, dataEntrega.precio_por_kg);
          setdatosTipos(dataEntrega.acopio_tipos);
          setPesoNeto(_pesoNeto);
          setImporteBruto(_pesoNeto * Number(dataEntrega.precio_por_kg))
        }
      }
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  useEffect(() => {
    getTipos();
    getLotes();
  }, []);

  const getLotes = () => {
    Api.get('admin/acopios/entregas/lotes', localStorage.getItem(`${env.appName}-ciclo_id`)).then(response => {
      let _lotes = [];
      _lotes.push({ value: '', label: 'Sin Asignar', folio: null, volumen: null });
      response.data.map(function(item) {
        _lotes.push({
          value: item['id'],
          label: item['nombre'] + (item['folio'] ? ' | ' + item['folio'] : ''),
          folio: item['folio'],
          volumen: item['volumen'],
        });
      });
      setLotes(_lotes);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  const getTipos = () => {
    Api.getAll('acopios/catalogos/certificaciones').then(response => {
      let _items = response.data.map(function(item) {
        return {
          value: item['id'],
          label: item['nombre'],
        };
      });
      setTipos(_items);
      // Set initial value
      // eslint-disable-next-line eqeqeq
    });
  };

  const agregaPesada = () => {
    append({
      id_acopio_pesadas: 0,
      id_cat_unidades_entregas: [],
      peso_bruto: '',
      peso_tara: '',
      peso_descuento: '',
      no_bultos: '',
      no_costales: '',
      no_tambores: '',
      no_sacos: '',
      no_costalillos: '',
      no_botes: '',
      bolsa_plastico: '',
      costal_yute: '',
      costal_pergaminero: '',
    });
  };

  const eliminaPesada = index => {
    remove(index);
  };

  return (
    <div>
      <Breadcrumb>
        <ol className="breadcrumb float-xl-right">
          <li className="breadcrumb-item">
            <Link to="/admin/acopio">Acopio</Link>
          </li>
          <li className="breadcrumb-item active">Entregas</li>
        </ol>
        <EntregasXProductorBuscador uuid_cuenta={uuid_cuenta} />
      </Breadcrumb>

      <CardContainer height={'100%'}>
        <div className="row">
          <div className="col-xl-8 col-lg-7">
            {showForm && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col">
                    <h3>{'Registro de nueva entrega'}</h3>

                    {/* Tipo de entrega inputs */}
                    <div className="row pt-3 pl-2">
                      <p className="titulo-input">Tipo de entrega</p>
                    </div>

                    <div className="row mb-4">
                      <div className="col-md-4">
                        <Controller
                          control={control}
                          name="fecha_ejecucion"
                          render={({ field }) => (
                            <DatePicker
                              customInput={<ExampleCustomInput />}
                              dateFormat={'dd/MMM/yyyy'}
                              onChange={date => field.onChange(date)}
                              selected={field.value}
                              ariaLabelledBy="cosa"
                              previousMonthButtonLabel=""
                              nextMonthButtonLabel=""
                              wrapperClassName="w-100"
                              locale={es}
                            />
                          )}
                        />
                        {errors?.fecha_ejecucion && <div className="invalid-feedback">{errors?.fecha_ejecucion.message}</div>}
                      </div>
                    </div>

                    <div className="row mb-4">
                      <div className="col-md-4">
                        <Controller
                          control={control}
                          name="id_cat_acopio_tipos_cafe"
                          render={() => (
                            <FormSelectMaterial
                              placeholder="Tipo de café"
                              label="Tipo de café"
                              options={tiposCafe}
                              id={'id_cat_acopio_tipos_cafe'}
                              error={errors.id_cat_acopio_tipos_cafe}
                              register={register}
                              onChange={handlerSelectTipoCafe}
                              value={idTipoCafe != '' ? idTipoCafe : getValues().id_cat_acopio_tipos_cafe || ''}
                            />
                          )}
                        />
                        {errors?.id_cat_acopio_tipos_cafe && (
                          <div className="invalid-feedback">{errors?.id_cat_acopio_tipos_cafe.message}</div>
                        )}
                      </div>
                      <div className="col-md-4">
                        <Controller
                          control={control}
                          name="id_cat_acopio_procesos"
                          render={() => (
                            <FormSelectMaterial
                              placeholder="Proceso"
                              label="Proceso"
                              options={procesosCafe}
                              id={'id_cat_acopio_procesos'}
                              error={errors.id_cat_acopio_procesos}
                              register={register}
                              onChange={handlerSelectProcesoCafe}
                              value={idProcesoCafe != '' ? idProcesoCafe : getValues().id_cat_acopio_procesos || ''}
                            />
                          )}
                        />
                        {errors?.id_cat_acopio_procesos && <div className="invalid-feedback">{errors?.id_cat_acopio_procesos.message}</div>}
                      </div>
                    </div>

                    {coopConfig.mostrarTipo() && (
                      <div className="row mb-4">
                        <div className="col-md-4">
                          <Controller
                            control={control}
                            name="id_cat_certificacion"
                            render={() => (
                              <FormSelectMaterial
                                placeholder="Categoría"
                                label="Categoría"
                                options={tipos}
                                name={'id_cat_certificacion'}
                                multiple={true}
                                error={errors?.id_cat_certificacion}
                                register={register}
                                onChange={handlerSelectChangeTipos}
                                value={idCatCertificacion || ''}
                              />
                            )}
                          />
                          {errors?.id_cat_certificacion && <div className="invalid-feedback">{errors?.id_cat_certificacion.message}</div>}
                          {(cuenta?.cuenta_categoria !== "Orgánico" && idCatCertificacion.includes(15)) && <div className="invalid-feedback">La categoría del productor no es orgánica. ¿Está seguro/a de esta categoría?</div>}
                        </div>
                      </div>
                    )}

                    {coopConfig.mostrarCostalera() && (
                      <>
                        <div className="row mt-1">
                          <div className="col">
                            <p className="titulo-pregunta">{'¿Se devuelven costaleras a la cooperativa?'}</p>
                          </div>
                        </div>

                        <div className="row g-3 mb-4 align-items-center">
                          <div className="col-auto">
                            <input
                              type="radio"
                              name="id_cat_costaleras_estatus"
                              value={1}
                              checked={Number(devuelvenCostaleras) === 1}
                              onChange={() => {
                                setValue('id_cat_costaleras_estatus', 1);
                                setDevuelvenCostaleras(1);
                              }}
                            />{' '}
                            Si
                          </div>
                          <div className="col-auto">
                            <input
                              type="radio"
                              name="id_cat_costaleras_estatus"
                              value={0}
                              checked={Number(devuelvenCostaleras) === 2}
                              onChange={() => {
                                setValue('id_cat_costaleras_estatus', 2);
                                setDevuelvenCostaleras(2);
                              }}
                            />{' '}
                            No
                          </div>
                          <div className="col-auto">
                            <input
                              type="radio"
                              name="id_cat_costaleras_estatus"
                              value={0}
                              checked={devuelvenCostaleras === null}
                              onChange={() => {
                                setValue('id_cat_costaleras_estatus', null);
                                setDevuelvenCostaleras(null);
                              }}
                            />{' '}
                            No aplica
                          </div>

                          {errors?.id_cat_costaleras_estatus && (
                            <div className="invalid-feedback">{errors?.id_cat_costaleras_estatus.message}</div>
                          )}
                        </div>
                      </>
                    )}

                    {+coopConfig?.config?.cooperativa?.producto?.id === ID_MIEL && (
                      <>
                        <div className="row mb-4 mt-2">
                          <div className="col-md-4">
                            <p className="titulo-pregunta mb-1">{'Fecha de cosecha'}</p>
                            <Controller
                              control={control}
                              name="fecha_cosecha"
                              render={({ field }) => (
                                <DatePicker
                                  customInput={<ExampleCustomInput />}
                                  dateFormat={'dd/MMM/yyyy'}
                                  onChange={date => field.onChange(date)}
                                  selected={field.value}
                                  wrapperClassName="w-100"
                                  locale={es}
                                />
                              )}
                            />
                            {errors?.fecha_cosecha && <div className="invalid-feedback">{errors?.fecha_cosecha.message}</div>}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {/* Datos de la pesada inputs */}
                <div className="row pt-3 pl-2">
                  <p className="titulo-input">Datos de pesada</p>
                </div>

                <div className="row pl-2 mt-2">
                  {estimacion && entrega && estimacion?.est_peso_estimado_total > 0 && (
                    <p className="info-input">
                      {'A este productor/a le faltan ' +
                        formatMoneyNumber((estimacion?.est_peso_estimado_total || 0) - (entrega?.entregas_peso_neto_total || 0)) +
                        ` ${coopConfig.acopioUnits()?.abreviacion_plural} para completar su estimación de cosecha`}
                    </p>
                  )}
                </div>

                {fields.map((pesada, index) => (
                  <div key={index}>
                    {fields.length > 1 && <h4 className="mt-1 mb-3">{'Pesada: ' + (index + 1)}</h4>}
                    <div className="row">
                      <div className="col-xl-4 col-lg-12">
                        <FormSelectMaterial
                          placeholder="Recipientes de entrega"
                          label="Recipientes de entrega"
                          options={unidadesEntregas}
                          name={`pesadas[${index}].id_cat_unidades_entregas`}
                          multiple={true}
                          error={errors.pesadas?.[index]?.id_cat_unidades_entregas}
                          register={register}
                          onChange={data => handlerSelectChange(data, index)}
                          value={getValues().pesadas[index].id_cat_unidades_entregas || ''}
                        />

                        {errors.pesadas?.[index]?.id_cat_unidades_entregas && (
                          <div className="invalid-feedback">{errors.pesadas?.[index]?.id_cat_unidades_entregas.message}</div>
                        )}
                      </div>

                      <EntregasXProductorBultos
                        errors={errors}
                        unidadesEntregas={unidadesEntregas}
                        id_cat_unidades_entregas={getValues().pesadas[index]?.id_cat_unidades_entregas}
                        nestIndex={index}
                        values={getValues()}
                        setValue={setValue}
                        {...{ control, register }}
                      />
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-3">
                        <FormInputMaterialTest
                          id={`pesadas[${index}].peso_bruto`}
                          register={register}
                          control={control}
                          error={errors.pesadas?.[index]?.peso_bruto}
                          placeholder={`Peso bruto (${coopConfig.acopioUnits()?.abreviacion_plural}.)`}
                          label=""
                          step=".01"
                          value={getValues().pesadas[index]?.peso_bruto}
                        />
                      </div>
                      <div className="col-md-3">
                        <FormInputMaterialTest
                          id={`pesadas[${index}].peso_tara`}
                          register={register}
                          control={control}
                          error={errors.pesadas?.[index]?.peso_tara}
                          placeholder={`Peso tara (${coopConfig.acopioUnits()?.abreviacion_plural}.)`}
                          label=""
                          step=".01"
                          value={getValues().pesadas[index]?.peso_tara}
                        />
                      </div>
                      {coopConfig.mostrarDescuento() && (
                        <div className="col-md-3">
                          <FormInputMaterialTest
                            id={`pesadas[${index}].peso_descuento`}
                            register={register}
                            control={control}
                            error={errors.pesadas?.[index]?.peso_descuento}
                            placeholder={`Peso descuento (${coopConfig.acopioUnits()?.abreviacion_plural}.)`}
                            label=""
                            step=".01"
                            value={getValues().pesadas[index]?.peso_descuento}
                          />
                        </div>
                      )}
                      {index > 0 && (
                        <div className="col-md-3">
                          <button
                            className="btn btn-outline-danger mx-1"
                            type="button"
                            onClick={() => {
                              eliminaPesada(index);
                            }}>
                            Eliminar
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
                <div className="row">
                  <button className="btn btn-default col-md-3 mt-3" type="button" onClick={agregaPesada}>
                    Agrega otra pesada
                  </button>
                </div>
                {pesoNeto && (
                  <div className="row pl-2">
                    <p className={formatMoneyNumber(pesoNeto) >= 0 ? 'info-input' : 'in-valid'}>
                      Peso neto ={' '}
                      <span style={{ fontSize: 24 }}>
                        {formatMoneyNumber(pesoNeto) + ' ' + coopConfig.acopioUnits()?.abreviacion_plural}
                      </span>
                    </p>
                      {pesoNeto > estimacion?.est_peso_estimado_total && (
                        <div className="invalid-feedback">El peso neto total <b>{pesoNeto} {coopConfig.acopioUnits()?.abreviacion_plural}</b> es mayor que la estimación de cosecha pendiente por entregar: <b>{estimacion?.est_peso_estimado_total || 0} {coopConfig.acopioUnits()?.abreviacion_plural}</b> </div>
                      )}
                  </div>
                )}

                {/* Muestreo */}
                {coopConfig.mostrarMuestreo && (
                  <>
                    <div className="row pt-3 pl-2">
                      <p className="titulo-input">{'Muestreo'}</p>
                    </div>

                    {!!+coopConfig?.config?.config_muestreo?.mostrar_tipo_maduracion && checkTipoCafe('tipo_maduracion') && (
                      <div className="row">
                        <div className="col-4 mb-4">
                          <FormInputMaterialTest
                            id="tipo_maduracion"
                            name="tipo_maduracion"
                            label=""
                            placeholder="Maduración (describe)"
                            error={errors.tipo_maduracion}
                            register={register}
                            step=".01"
                            value={getValues().tipo_maduracion}
                          />
                        </div>
                      </div>
                    )}
                    <div className="row">
                      {!!+coopConfig?.config?.config_muestreo?.mostrar_rendimiento && checkTipoCafe('rendimiento') && (
                        <div className="col-md-4 mb-4">
                          <FormInputMaterialTest
                            id="rendimiento"
                            name="rendimiento"
                            label=""
                            placeholder="Rendimiento (%)"
                            error={errors.rendimiento}
                            register={register}
                            step=".01"
                            value={getValues().rendimiento}
                          />
                        </div>
                      )}

                      {!!+coopConfig?.config?.config_muestreo?.mostrar_humedad && checkTipoCafe('humedad') && (
                        <div className="col-md-4 mb-4">
                          <FormInputMaterialTest
                            id="humedad"
                            name="humedad"
                            label=""
                            placeholder="Humedad (%)"
                            error={errors.humedad}
                            register={register}
                            step=".01"
                            value={getValues().humedad}
                          />
                        </div>
                      )}
                      {!!+coopConfig?.config?.config_muestreo?.mostrar_mancha && checkTipoCafe('mancha') && (
                        <div className="col-md-4 mb-4">
                          <FormInputMaterialTest
                            id="mancha"
                            name="mancha"
                            label=""
                            placeholder="Mancha (%)"
                            error={errors.mancha}
                            register={register}
                            step=".01"
                            value={getValues().mancha}
                          />
                        </div>
                      )}
                      {!!+coopConfig?.config?.config_muestreo?.mostrar_color_de_cafe && checkTipoCafe('color_de_cafe') && (
                        <div className="col-md-4 mb-4">
                          <FormInputMaterialTest
                            id="color_de_cafe"
                            name="color_de_cafe"
                            label=""
                            placeholder="Color (describe)"
                            error={errors.color_de_cafe}
                            register={register}
                            step=".01"
                            value={getValues().color_de_cafe}
                          />
                        </div>
                      )}
                      {!!+coopConfig?.config?.config_muestreo?.mostrar_color && (
                        <div className="col-md-4 mb-4">
                          <FormSelectMaterial
                            placeholder="Color"
                            label="Color"
                            options={coloresMiel}
                            id={'id_color'}
                            error={errors.id_color}
                            register={register}
                            onChange={handlerSelectColorMiel}
                            value={idColorMiel || ''}
                          />
                          {errors?.id_color && <div className="invalid-feedback">{errors?.id_color.message}</div>}
                        </div>
                      )}
                      {!!+coopConfig?.config?.config_muestreo?.mostrar_aroma && checkTipoCafe('aroma') && (
                        <div className="col-md-4 mb-4">
                          <FormInputMaterialTest
                            id="aroma"
                            name="aroma"
                            label=""
                            placeholder="Aroma (describe)"
                            error={errors.aroma}
                            register={register}
                            step=".01"
                            value={getValues().color_de_cafe}
                          />
                        </div>
                      )}
                      {!!+coopConfig?.config?.config_muestreo?.mostrar_observaciones && (
                        <div className="col-md-4 mb-4">
                          <FormInputMaterialTest
                            id="muestreo_observaciones"
                            name="muestreo_observaciones"
                            label=""
                            placeholder="Observaciones"
                            error={errors.muestreo_observaciones}
                            register={register}
                            step=".01"
                            value={getValues().muestreo_observaciones}
                          />
                        </div>
                      )}
                    </div>
                  </>
                )}

                {/* Lotes */}
                {coopConfig.mostrarLote() && (
                  <>
                    <div className="row pt-3 pl-2">
                      <p className="titulo-input">
                        Lote
                        <span style={{ fontSize: 10, paddingLeft: 5 }}>¿Quieres asignar a esta entrega un lote?</span>
                      </p>
                    </div>

                    <div className="row">
                      <div className="col-md-4 mb-4">
                        <Controller
                          control={control}
                          name="id_recoleccion_acopio"
                          render={() => (
                            <Select
                              isLoading={lotes.length === 0}
                              components={{ DropdownIndicator }}
                              classNamePrefix="_select_"
                              isSearchable={true}
                              isClearable={true}
                              autosize={false}
                              style={{zIndex: 50, opacity: 1}}
                              styles={customStyles}
                              placeholder="Nº o código de lote"
                              options={lotes}
                              noOptionsMessage={() => 'No hay elementos por mostrar'}
                              onChange={handleChange}
                              input={(provided) => ({
                                ...provided,
                                input: {
                                  opacity: "1 !important",
                                },
                              })}
                              value={lotes.filter(unidad => unidad.value === getValues().id_recoleccion_acopio)}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </>
                )}

                {/* Orphan columns */}
                <div className="row">
                  <div className="col-md-4 mb-4">
                    <FormInputMaterialTest
                      id="observaciones"
                      name="observaciones"
                      label=""
                      placeholder="Observaciones"
                      error={errors.observaciones}
                      register={register}
                      step=".01"
                      value={getValues().observaciones}
                    />
                  </div>
                </div>

                {/* Pagos */}
                <div className="row pt-3 pl-2">
                  <p className="titulo-input">{'Pago'}</p>
                </div>

                <div className="row mb-2">
                  <div className="col-xl-4 col-lg-6">
                    <FormInputMaterialTest
                      id="precio_por_kg"
                      name="precio_por_kg"
                      label=""
                      placeholder={`Precio / ${coopConfig.acopioUnits()?.abreviacion} (${coopConfig.currency()?.symbol})`}
                      error={errors.precio_por_kg}
                      register={register}
                      step=".01"
                      value={getValues().precio_por_kg}
                    />
                  </div>
                  {importeBruto && getValues().precio_por_kg && (
                    <div className="col-xl-4 col-lg-6">
                      <p className="importe-total">{`Importe bruto ${coopConfig.currency()?.symbol} ` + formatMoneyNumber(importeBruto)}</p>
                    </div>
                  )}
                </div>

                {/* Créditos */}
                {getIdCatCreditoPrecosecha() > 1 && pendientePagoCredito > 0 && (
                  <>
                    <div className="row pl-2">
                      <p className="info-input">
                        {`Este productor tiene ${coopConfig.currency()?.symbol} ` +
                          formatMoneyNumber(pendientePagoCredito) +
                          ' pendientes de pago de ' +
                          transformWord(etiquetaAnticipos, ['plural'])}
                      </p>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <FormInputMaterialTest
                          id="recuperacion_credito"
                          name="recuperacion_credito"
                          label=""
                          placeholder={
                            'Recuperación ' + transformWord(etiquetaAnticipos, ['plural']) + ` (${coopConfig.currency()?.symbol})`
                          }
                          error={errors.recuperacion_credito}
                          register={register}
                          step=".01"
                          value={getValues().recuperacion_credito}
                        />
                      </div>
                      {montoPagar && (
                        <div className="col-md-6">
                          <p className="importe-total">
                            {`Monto a pagar ${coopConfig.currency()?.symbol} ` + formatMoneyNumber(montoPagar)}
                          </p>
                        </div>
                      )}
                    </div>
                  </>
                )}

              {isValidPositiveNumber(precioKgValue) && (
                  <div className="row mb-2">
                    <div className="col-xl-4 col-lg-6">
                      <FormSelectMaterial
                        placeholder="Método de pago"
                        label="Método de pago"
                        options={metodosPago}
                        id={'id_metodo_pago'}
                        error={errors.id_metodo_pago}
                        register={register}
                        onChange={handlerSelectMetodoPago}
                        value={idMetodoPago || ''}
                        disabled={metodosPago.length === 1}
                      />
                      {errors?.id_metodo_pago && <div className="invalid-feedback">{errors?.id_metodo_pago.message}</div>}
                    </div>
                    <div className="col-xl-4 col-lg-6 mt-1">
                      <FormInputMaterialTest
                        id="monto_pagado"
                        name="monto_pagado"
                        label=""
                        placeholder={`Monto pagado (${coopConfig.currency()?.symbol})`}
                        error={errors.monto_pagado}
                        register={register}
                        step=".01"
                        value={getValues().monto_pagado}
                      />
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-md-6">
                    <button className="btn btn-sirio btn-lg mt-3" type="submit">
                      Continuar
                    </button>
                  </div>
                </div>
              </form>
            )}
            {showFirma && (
              <EntregasXProductorFirma
                metodosPago={metodosPago}
                data={dataForm}
                cuenta={cuenta}
                importeBruto={importeBruto}
                pesoNeto={pesoNeto}
                unidadesEntregas={unidadesEntregas}
              />
            )}
          </div>
          <div className="col-xl-4 col-lg-5">
            <EntregasXProductorInfo uuid_cuenta={uuid_cuenta} id_cuenta={id_cuenta} />
          </div>
        </div>
        {/*
        <button onClick={() => {
          setShowForm(!showForm);
          setShowFirma(!showFirma);
        }} className="btn btn-sirio mt-3">Continuar</button>
      */}
      </CardContainer>
    </div>
  );
};

export default EntregasXProductor;
