import xlsx from "json-as-xlsx";
import moment from 'moment';

const LotesExport = (data,ciclo,estados) => {
    let xlsData = [
      {
        sheet: 'Lotes',
        columns: [
          { label: 'NOMBRE', value: 'nombre' },
          { label: 'CÓDIGO', value: 'folio' },
          { label: "VOLUMEN A ENTREGAR", value: (row) => {
            if (Number(row.volumen) > 0) {
              return Number(row.volumen);
            }
            return 0
            },
            format: '0.00'
          },
          { label: "VOLUMEN ACOPIADO", value: (row) => {
            if (Number(row.lote_kgs_entregados) > 0) {
              return Number(row.lote_kgs_entregados);
            }
            return 0
            },
            format: '0.00'
          },
          { label: "AVANCE ACOPIO", value: (row) => {
              if (Number(row.volumen) > 0) {
                const avance = (Number(row.lote_kgs_entregados) / Number(row.volumen));
                return Number(avance.toFixed(2));
              }
              return 0;
            },
            format: '%'
          },
          { label: 'ESTADO', value: (row) => estados.find(it => it.value == row.id_recoleccion_acopio_estatus).label },
          { label: 'FECHA DE ENTREGA O VENTA', value: 'fecha_entrega_venta' },
          { label: 'OBSERVACIONES', value: 'observaciones' }
        ],
        content: data,
      },
    ];

    let settings = {
      fileName: `Lotes_${ciclo.id}_${moment().format('YYYYMMDD_HHmmss')}`,
    };
    xlsx(xlsData, settings);
  };

  export default LotesExport;
