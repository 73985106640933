import React, { useState, forwardRef, useImperativeHandle, useEffect } from 'react';
import Api from '../../../../api/global.service';
import 'react-datepicker/dist/react-datepicker.css';
import * as yup from 'yup';
import { publish } from '../../../../components/events.js';
import { validacionNumero2DecimalesNotRequired } from '../../../../nuup/utils/validaciones/validacionNumero2Decimales.js';
import TextField from '@mui/material/TextField';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { useCoopConfig } from '../../../../components/content/coop-config.jsx';

const ModalNuevoLote = forwardRef((props, ref) => {
  const customStyles = {
    control: base => ({
      ...base,
      flexDirection: 'row-reverse',
      width: '100%',
    }),
    placeholder: defaultStyles => {
      return {
        ...defaultStyles,
        fontSize: 18,
        fontWeight: 400,
      };
    },
  };

  const coopConfig = useCoopConfig();
  const [idCiclo, setIdCiclo] = useState(0);
  const [show, setShow] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [id, setId] = useState(0);
  const [uuid, setUuid] = useState(uuidv4());
  const [nombre, setNombre] = useState('');
  const [folio, setFolio] = useState('');
  const [volumen, setVolumen] = useState('');
  const [fechaEntregaVenta, setFechaEntregaVenta] = useState('');
  const [observaciones, setObservaciones] = useState('');

  const [errors, setErrors] = useState(null);
  const schema = yup.object().shape({
    id: yup
      .number()
      .min(0)
      .required('Debe escribir un nombre'),
    nombre: yup.string().required('Debe escribir un nombre'),
    folio: yup.string().nullable(),
    volumen: validacionNumero2DecimalesNotRequired(),
    fecha_entrega_venta: yup
      .date()
      .max(moment().add(24, 'M'), 'Seleccione otra fecha')
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable()
      .notRequired(),
    observaciones: yup.string().nullable(),
  });

  //To call outside
  useImperativeHandle(ref, () => ({
    show: showHandler,
    setIdCiclo: id => setIdCiclo(id),
    setData: data => setData(data),
    close: closeHandler,
  }));

  const setData = data => {
    setId(data.id);
    setUuid(data.uuid);
    setNombre(data.nombre);
    setFolio(data.folio);
    setVolumen(data.volumen);
    setFechaEntregaVenta(data.fecha_entrega_venta);
    setErrors(null);
    setShow(true);
    setObservaciones(data.observaciones);
  };

  const showHandler = () => {
    setId(0);
    setUuid(uuidv4());
    setNombre('');
    setFolio('');
    setVolumen('');
    setFechaEntregaVenta('');
    setErrors(null);
    setShow(true);
    setObservaciones('');
  };

  const closeHandler = () => {
    setId(0);
    setUuid('');
    setNombre('');
    setFolio('');
    setVolumen('');
    setFechaEntregaVenta('');
    setErrors(null);
    setShow(false);
    setObservaciones('');
  };

  const validateHandler = () => {
    setBlocking(true);

    var data = {
      id: id,
      nombre: nombre,
      folio: folio,
      volumen: volumen,
      fecha_entrega_venta: fechaEntregaVenta,
      observaciones: observaciones,
    };

    var that = this;
    schema
      .validate(data, { abortEarly: false })
      .then(function(valid) {
        //Datos validos
        const data = {
          id: id,
          uuid: uuid,
          nombre: nombre,
          folio: folio,
          volumen: volumen,
          fecha_entrega_venta: fechaEntregaVenta,
          id_ciclo: idCiclo,
          observaciones: observaciones,
        };

        if (data.id > 0) {
          actualizaLote(data);
        } else {
          creaLote(data);
        }
      })
      .catch(function(err) {
        let errors = err.inner.reduce(function(map, obj) {
          map[obj.path] = obj.message;
          return map;
        }, {});
        setErrors(errors);
        setBlocking(false);
      });
  };

  const creaLote = data => {
    Api.Acopio.create('lotes', data)
      .then(response => {
        if (response.data.success) {
          setBlocking(false);
          closeHandler();
          publish('Lote::onSave', data);
        } else {
          let err = response.data.data;
          setErrors(err);
          setBlocking(false);
        }
      })
      .catch(e => {
        setBlocking(false);
      });
  };

  const actualizaLote = data => {
    Api.Acopio.update('lotes', uuid, data)
      .then(response => {
        if (response.data.success) {
          setBlocking(false);
          closeHandler();
          publish('Lote::onSave', data);
        } else {
          let err = response.data.data;
          setErrors(err);
          setBlocking(false);
        }
      })
      .catch(e => {
        setBlocking(false);
      });
  };

  return (
    <div className="modal" style={{ display: show ? 'block' : 'none', backgroundColor: 'rgba(0,0,0,0.2)' }}>
      <div className="modal-dialog" style={{ top: 0, right: 25, position: 'absolute', width: 400, height: 'calc(100% - 50px)' }}>
        <div className="modal-content" style={{ height: '100%' }}>
          <div className="modal-header" style={{ flex: 'inherit' }}>
            <h4 className="modal-title">{props.title}</h4>
            <button onClick={() => closeHandler()} type="button" className="btn" style={{ borderRadius: '20px 20px 20px 20px' }}>
              <i className="fa fa-times"></i>
            </button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-12">
                <TextField
                  name="nombre"
                  error={errors?.nombre}
                  label="Nombre (ej: Lote 1)"
                  className="form-control"
                  value={nombre}
                  onChange={event => {
                    setNombre(event.target.value);
                  }}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-5">
                <TextField
                  name="folio"
                  error={errors?.folio}
                  label="Folio o código"
                  className="form-control"
                  value={folio}
                  onChange={event => {
                    setFolio(event.target.value);
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <span>Opcional</span>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <TextField
                  type="number"
                  name="volumen"
                  error={errors?.volumen}
                  label={`Volumen (${coopConfig.acopioUnits()?.abreviacion_plural}.)`}
                  className="form-control"
                  value={volumen}
                  onChange={event => {
                    setVolumen(event.target.value);
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                {errors?.volumen ? <div className="invalid-feedback">{errors.volumen}</div> : <span>Opcional</span>}
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-5">
                <TextField
                  name="observaciones"
                  error={errors?.observaciones}
                  label="Observaciones"
                  className="form-control"
                  value={observaciones || ''}
                  onChange={event => {
                    setObservaciones(event.target.value);
                  }}
                />
              </div>
              <div className="col-12 mt-4">
                <span>Opcional</span>
              </div>
            </div>

            <div className="row">
              <div className="col-12 mt-3">
                <TextField
                  type="date"
                  name="fecha_entrega_venta"
                  error={errors?.fecha_entrega_venta}
                  label="Fecha entrega o venta"
                  className="form-control"
                  value={fechaEntregaVenta}
                  onChange={event => {
                    setFechaEntregaVenta(event.target.value);
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </div>

              <div className="col-12 mt-4">
                {errors?.fecha_entrega_venta ? <div className="invalid-feedback">{errors.fecha_entrega_venta}</div> : <span>Opcional</span>}
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {blocking && (
              <div className="spinner-border text-warning" role="status">
                <span className="sr-only">Guardando...</span>
              </div>
            )}
            {!blocking && (
              <>
                <button
                  onClick={() => closeHandler()}
                  type="button"
                  className="btn button-hover"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-times"></i> Cancelar
                </button>
                <button
                  disabled={blocking}
                  onClick={() => validateHandler()}
                  type="button"
                  className="btn btn-sirio"
                  style={{ borderRadius: '20px 20px 20px 20px' }}>
                  <i className="fa fa-save"></i> Guardar
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ModalNuevoLote;
